import { useEffect, useState } from "react";
import styles from "./Answer.module.css";
import {
  Box,
  Flex,
  Text,
  Link,
  Spacer,
  Icon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  Checkbox,
} from "@chakra-ui/react";
import { FeedbackRequest, ChatTurn, MlApi, Conversation } from "../../api";
import { parseAnswerToHtml, HtmlParsedAnswer } from "./AnswerParser";
import { IoIosThumbsDown, IoIosThumbsUp } from "react-icons/io";
import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";

// 1) Import useUserContext
import { useUserContext } from "../../context/UserContext"; // Adjust path as necessary

interface Props {
  api: MlApi;
  question: string;
  answer: ChatTurn;
  history: ChatTurn[];
  isSelected?: boolean;
  onCitationClicked: (citation: string, index: number) => void;
  onThoughtProcessClicked?: () => void;
  onSupportingContentClicked: () => void;
  onFollowupQuestionClicked?: (question: string) => void;
  showFollowupQuestions?: boolean;
  conversation?: Conversation;
  disableButtons?: boolean;
  isWelcomeMessage?: boolean;
}

const enum FeedbackState {
  Default,
  Liked,
  Disliked,
}

export const Answer = ({
  api,
  question,
  answer,
  history,
  isSelected,
  onCitationClicked,
  onThoughtProcessClicked,
  onSupportingContentClicked,
  onFollowupQuestionClicked,
  showFollowupQuestions,
  conversation,
  disableButtons,
  isWelcomeMessage,
}: Props) => {
  const [parsedAnswer, setParsedAnswer] = useState<HtmlParsedAnswer>();
  const [rawAnswer, setRawAnswer] = useState<string>("");
  const [feedbackState, setFeedbackState] = useState<FeedbackState>(
    FeedbackState.Default
  );
  const [citations, setCitations] = useState<string[]>([]);
  const [sendEmail, setSendEmail] = useState<boolean>(true);

  // 2) Grab user's actual email from user context
  const { companyName, email } = useUserContext();

  // For the popovers
  const {
    isOpen: isLikePopoverOpen,
    onOpen: onLikePopoverOpen,
    onClose: onLikePopoverClose,
  } = useDisclosure();
  const {
    isOpen: isDislikePopoverOpen,
    onOpen: onDislikePopoverOpen,
    onClose: onDislikePopoverClose,
  } = useDisclosure();

  // For the “Thought Process” modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    parseAnswerToHtml(api, answer.content, conversation).then((parsed) => {
      setParsedAnswer(parsed);
      setRawAnswer(parsed.answerHtml);
      setCitations(parsed.citations.map((x) => x.name));
    });
  }, [answer.content, conversation]);

  // We pass the user's email if sendEmail is true, otherwise "NO"
  const saveFeedback = async (kind: number, shouldSendEmail: boolean) => {
    const data: FeedbackRequest = {
      question: question,
      answer: rawAnswer,
      history: history,
      citations: citations,
      kind: kind,
      // If checkbox is checked, store the user's actual email. Otherwise "NO"
      userEmail: shouldSendEmail ? email : "NO",
    };
    await api.feedback(data);
  };

  const isLiked = (f: FeedbackState) => f === FeedbackState.Liked;
  const isDisliked = (f: FeedbackState) => f === FeedbackState.Disliked;
  const isDefault = (f: FeedbackState) => f === FeedbackState.Default;

  // When user clicks thumbs-up
  const onLikeClick = () => {
    if (isDefault(feedbackState)) {
      setFeedbackState(FeedbackState.Liked);
    }
    onLikePopoverOpen();
  };

  // When user clicks “Send” in thumbs-up popover
  const onLikeSend = async () => {
    await saveFeedback(1, sendEmail);
    onLikePopoverClose();
  };

  // When user clicks thumbs-down
  const onDislikeClick = () => {
    if (isDefault(feedbackState)) {
      setFeedbackState(FeedbackState.Disliked);
    }
    onDislikePopoverOpen();
  };

  // When user clicks “Send” in thumbs-down popover
  const onDislikeSend = async () => {
    await saveFeedback(-1, sendEmail);
    onDislikePopoverClose();
  };

  // Build the feedback buttons, each with its own popover
  const feedbackButtons = (
    <Flex direction="row">
      <Popover
        isOpen={isLikePopoverOpen}
        onClose={onLikePopoverClose}
        placement="top"
        closeOnBlur={false}
        closeOnEsc={false}
      >
        <PopoverTrigger>
          <IconButton
            aria-label="Like answer"
            icon={<Icon as={IoIosThumbsUp} />}
            onClick={onLikeClick}
            isDisabled={!isDefault(feedbackState)}
            color={isLiked(feedbackState) ? "green" : "default"}
          />
        </PopoverTrigger>
        <PopoverContent className={styles.comicPopover}>
          <PopoverArrow />
          <PopoverHeader>Help us to improve our bot!</PopoverHeader>
          <PopoverBody>
            <Checkbox
              isChecked={sendEmail}
              onChange={(e) => setSendEmail(e.target.checked)}
            >
              Yes, I agree to send my email with this feedback.
            </Checkbox>
          </PopoverBody>
          <PopoverFooter>
            <Button colorScheme="blue" onClick={onLikeSend}>
              Send
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Popover>

      <Box width="1em" />

      <Popover
        isOpen={isDislikePopoverOpen}
        onClose={onDislikePopoverClose}
        placement="top"
        closeOnBlur={false}
        closeOnEsc={false}
      >
        <PopoverTrigger>
          <IconButton
            aria-label="Dislike answer"
            icon={<Icon as={IoIosThumbsDown} />}
            onClick={onDislikeClick}
            isDisabled={!isDefault(feedbackState)}
            color={isDisliked(feedbackState) ? "red" : "default"}
          />
        </PopoverTrigger>
        <PopoverContent className={styles.comicPopover}>
          <PopoverArrow />
          <PopoverHeader>Help us to improve our bot!</PopoverHeader>
          <PopoverBody>
            <Checkbox
              isChecked={sendEmail}
              onChange={(e) => setSendEmail(e.target.checked)}
            >
              Yes, I agree to send my email with this feedback.
            </Checkbox>
          </PopoverBody>
          <PopoverFooter>
            <Button colorScheme="blue" onClick={onDislikeSend}>
              Send
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Flex>
  );

  const formatDisplayName = (url: string) => {
    const decodedUrl = decodeURIComponent(url);
    const fileName = decodedUrl.split("/").pop() || "Unknown File";
    return fileName.split("?")[0].replace(/_/g, " ");
  };

  // Remove duplicate URLs
  const uniqueUrls = Array.from(new Set(answer.urls));

  return (
    <Flex
      direction="column"
      className={`${styles.answerContainer} ${
        isSelected && styles.selected
      }`}
    >
      {!disableButtons && (
        <Flex>
          {feedbackButtons}
          <Spacer />
        </Flex>
      )}

      {/* Render the parsed answer as HTML */}
      <div
        className={styles.answerText}
        dangerouslySetInnerHTML={{ __html: rawAnswer }}
      />

      {/* Display supporting content (urls) */}
      {uniqueUrls.length > 0 && (
        <Flex wrap="wrap" gap={2} mt={4}>
          <Box width="100%" mt={4}>
            <Text className={styles.citationLearnMore}>
              Supporting Content Links:
            </Text>
          </Box>
          {uniqueUrls.map((url, i) => (
            <Link
              key={i}
              className={styles.citation}
              title={formatDisplayName(url)}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              mx={2}
            >
              {`${i + 1}. ${formatDisplayName(url)}`}
            </Link>
          ))}
        </Flex>
      )}

      {/* Thought Process button and modal */}
      {!isWelcomeMessage && answer.cognitive_search_query && (
        <Box mt="4">
          <Button onClick={onOpen} colorScheme="teal" variant="solid">
            Check the Thought Process
          </Button>
        </Box>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Thought Process</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto">
            <Text mb="4" fontWeight="bold">
              Cognitive Search Query:
            </Text>
            <ReactMarkdown
              components={ChakraUIRenderer()}
              className={styles.answerText}
              children={
                answer.cognitive_search_query || "No thought process available"
              }
              skipHtml
            />
            <Text mt="4" mb="4" fontWeight="bold">
              Augmented Content:
            </Text>
            <ReactMarkdown
              components={ChakraUIRenderer()}
              className={styles.answerText}
              children={
                answer.augmented_content || "No augmented content available"
              }
              skipHtml
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} colorScheme="blue">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
