import { useEffect, useState, Fragment } from "react";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import Browser from "./pages/browser/Browser";
import { BackendContext } from "./context";
import { ApiParameters } from "./api";
import ChatWithYourData from "./pages/browser/ChatWithYourData";
import ChatWithDataModule from "./pages/chat/ChatWithDataModule";

import {
  InteractionRequiredAuthError,
  InteractionType,
  PopupRequest,
} from "@azure/msal-browser";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";

import { Spinner, useToast } from "@chakra-ui/react";
import { UserProvider } from "./context/UserContext";

import appInsights from "./utils/appInsights"; // Import the Application Insights instance
import "./index.css";

// Replace with whichever scope(s) your app needs
const request: PopupRequest = {
  scopes: [import.meta.env.VITE_AAD_BE_API],
};

const initLogin = async (login: any) => {
  try {
    const response = await login(InteractionType.Popup, request);
    return response;
  } catch (err: any) {
    throw Error(err);
  }
};

// Component to handle routes and track page views
const AppRoutes = ({ instance, homeAccountId, api }: { instance: any, homeAccountId: string, api: any }) => {
  const location = useLocation();

  // Track a page view whenever the route changes
  useEffect(() => {
    appInsights.trackPageView({ name: location.pathname });
  }, [location]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout instance={instance} homeAccountId={homeAccountId} />
        }
      >
        <Route index element={<Browser />} />
        <Route path="ai">
          <Route path="chat" element={<Chat />} />
          <Route path="chat/:partitionKey/:rowKey" element={<Chat />} />
        </Route>
        <Route path="cwyod">
          <Route index element={<ChatWithDataModule />} />
          <Route path="create" element={<ChatWithYourData />} />
          <Route
            path="chat/:partitionKey/:rowKey"
            element={<ChatWithDataModule />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

const App = () => {
  const { instance, accounts } = useMsal();
  const toast = useToast();
  const [homeAccountId, setHomeAccountId] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);

  // Ensure an active account is set before authentication begins
  useEffect(() => {
    if (accounts.length > 0 && !instance.getActiveAccount()) {
      instance.setActiveAccount(accounts[0]);
    }
    setInitialized(true);
  }, [instance, accounts]);

  // Only call the authentication hook after initialization
  const { login, error, acquireToken } = useMsalAuthentication(
    InteractionType.Silent,
    request,
  );

  useEffect(() => {
    if (!initialized) return; // Wait until active account is set

    (async () => {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          // Fallback to an interactive popup if silent fails
          const res = await initLogin(login);
          if (!res) {
            toast({
              title: "Error",
              description: `Failed to login after popup: ${error}`,
              status: "error",
              duration: 4000,
              isClosable: true,
            });
            return;
          }
          // Set the active account after interactive login
          instance.setActiveAccount(res.account);
          console.debug("LoginPopup succeeded, setting active account");
          setHomeAccountId(res.account.homeAccountId);
        } catch (loginError) {
          toast({
            title: "Login Error",
            description: `Error during popup login: ${loginError}`,
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        }
      } else if (error === null) {
        // If there's no error, silent login must have worked
        const accs = instance.getAllAccounts();
        if (accs.length === 0) {
          console.error("No login error but no active accounts found!");
        } else {
          // Set the first account as active
          instance.setActiveAccount(accs[0]);
          console.debug(
            `LoginSilent succeeded, setting active account from available accounts.`
          );
          setHomeAccountId(accs[0].homeAccountId);
        }
      } else {
        // Some other error
        toast({
          title: "Error",
          description: `Login failed for unknown reason: ${error}`,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
      setLoading(false);
    })();
  }, [initialized, error, instance, login, toast]);

  // For the rest of your app, acquire tokens from this "api" object
  const api = new ApiParameters();
  api.setTokenAcquirer(acquireToken);

  // Content shown if user is not authenticated
  const unAuthContent = (
    <p>
      You must be signed in to see this page. Check if your browser is blocking popup windows.
    </p>
  );

  // Wait for initialization before rendering
  if (!initialized || loading) {
    return (
      <div className="spinner">
        <Spinner
          alignSelf="center"
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </div>
    );
  }

  return (
    <Fragment key={homeAccountId}>
      <UnauthenticatedTemplate>{unAuthContent}</UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <UserProvider>
          <BackendContext.Provider value={api}>
            <BrowserRouter>
              <AppRoutes instance={instance} homeAccountId={homeAccountId} api={api} />
            </BrowserRouter>
          </BackendContext.Provider>
        </UserProvider>
      </AuthenticatedTemplate>
    </Fragment>
  );
};

export default App;
