import React, { useRef, useState, useEffect } from "react";
import axios from "axios"; 
import {
  Flex,
  Box,
  Drawer,
  useDisclosure,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Input,
  Textarea,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Checkbox,
  SliderMark,
  CheckboxGroup,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import styles from "./Chat.module.css";
import {
  MlApi,
  Approaches,
  ChatRequest,
  ChatTurn,
  ConversationApi,
  OneConversationResponse,
} from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import {
  AnalysisPanel,
  AnalysisPanelTabs,
} from "../../components/AnalysisPanel";
import { ClearButton } from "../../components/ClearButton";
import { useLocation } from "react-router-dom";
import { useUserContext } from "../../context/UserContext"; 
import { detectLanguage, translateText } from "../services/translationService"; 
import { useRowKeyService } from "../services/rowKeyService"; 


const categories = [{ id: "sharepoint", name: "Sharepoint" }];

export interface Category {
  id: string;
  name: string;
}
interface CategoriesList {
  category: Category[];
}

interface ChatProps {
  isRSI?: boolean; // Optional prop to indicate RSI mode
}

const Chat: React.FC<ChatProps> = ({ isRSI = false }) => {
  const { companyName, email } = useUserContext();

  // Wait until companyName is available before proceeding
  if (!companyName) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner
          alignSelf="center"
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    );
  }

  // Now proceed with the rest of the component
  // console.log("company name:", companyName);
  // console.log("email:", email);


  const [detectedLanguage, setDetectedLanguage] = useState<string>("en"); // Default to English

  // Define partitionKeys/rowKeys for the other conversations
  const partitionKey1 = "0";
  const rowKey1 = "13dc75f3b9664fe0a2b19f19e95dd14a"; // IMS General
  const partitionKey2 = "0";
  const rowKey3 = "bf88d80c7c6245ad9039fa0b43ce9372"; // RSI Knowledge

 

  // State variables for storing conversation data
  const [oneConversation, setOneConversation] =
    useState<OneConversationResponse | null>(null);
  const [oneConversation2, setOneConversation2] =
    useState<OneConversationResponse | null>(null);
  const [oneConversation3, setOneConversation3] =
    useState<OneConversationResponse | null>(null);

  // State to track rowKey2 fetched from your Azure Function
  const [rowKey2, setRowKey2] = useState<string>("");

  const { state } = useLocation();
  const { conversation } = state || {};

  const api1 = new MlApi(conversation);
  const conversationApi1 = new ConversationApi();

  const api2 = new MlApi(conversation);
  const conversationApi2 = new ConversationApi();

  const api3 = new MlApi(conversation);
  const conversationApi3 = new ConversationApi();

  const {
    isOpen: isConfigPanelOpen,
    onOpen: onConfigPanelOpen,
    onClose: onConfigPanelClose,
  } = useDisclosure();
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const [promptTemplate, setPromptTemplate] = useState<string>("");
  const [retrieveCount, setRetrieveCount] = useState<number>(3);
  const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
  const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(
    false
  );
  const [selectedCategories, setSelectedCategories] = useState<CategoriesList>({
    category: categories,
  });
  const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] =
    useState<boolean>(false);

  const lastQuestionRef = useRef<string>("");
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [activeCitation, setActiveCitation] = useState<string>("");
  const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] =
    useState<AnalysisPanelTabs>(AnalysisPanelTabs.Closed);

  const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
  const [history, setHistory] = useState<ChatTurn[]>([]);

  const getIMSLocation = (company:string) => {
    const strippedCompany = company.replace(/^ILF\s+/, '').trim();
  
    return `IMS ${strippedCompany}`;
  };
  const imsLocation = getIMSLocation(companyName);

  const welcomeMessage = isRSI
    ? `Welcome to the ILF Spark Bot! Ask questions relevant to the following source document libraries:
**- RSI Knowledge**

and I'll do my best to assist. Keep in mind that the answers are AI-generated, so ensure to check the answer quality based on the referenced content.
`
    : `Welcome to the ILF Spark Bot! Ask questions relevant to the following source document libraries:
**- IMS General**
**- ${imsLocation}**

and I'll do my best to assist. Keep in mind that the answers are AI-generated, so ensure to check the answer quality based on the referenced content.
`;

const fetchRowKey = useRowKeyService();

  useEffect(() => {
    const fetchConversation = async () => {
      // 3a) If not RSI, we handle conversation1 + rowKey2 => conversation2
      if (!isRSI) {
        // Conversation 1
        if (rowKey1) {
          try {
            const r1 = await conversationApi1.getOne(partitionKey1, rowKey1);
            setOneConversation(r1);
          } catch (error: any) {
            // console.error("Error fetching conversation1:", error);
          }
        } else {
          setOneConversation(null);
        }
        const strippedCompany = companyName.replace(/^ILF\s*/, "");
        let tempRowKey2="";
      
          try {
            const rowKey2 = await fetchRowKey(strippedCompany);
            console.log("Fetched rowKey2:", rowKey2);
            tempRowKey2 = rowKey2;
            console.log("tempRowKey2:", tempRowKey2);

          } catch (apiError) {
          // If the API fails, fallback to a default rowKey
          console.error("Error fetching rowKey2 from API:", apiError);
          tempRowKey2 = "6318c95313174a97bc14aed97a24da7f"; // fallback
        }
      
        // 3c) Once we have tempRowKey2 (either from API or fallback), fetch conversation2
        if (tempRowKey2) {
          setRowKey2(tempRowKey2); // store it in state if you want to use it later
          try {
            const r2 = await conversationApi2.getOne(partitionKey2, tempRowKey2);
            setOneConversation2(r2);
          } catch (error: any) {
            // console.error("Error fetching conversation2:", error);
          }
        } else {
          setOneConversation2(null);
        }
      } else {
        // RSI scenario => fetch conversation 3 only
        if (rowKey3) {
          try {
            const r3 = await conversationApi3.getOne(partitionKey1, rowKey3);
            setOneConversation3(r3);
          } catch (error: any) {
            // console.error("Error fetching conversation3:", error);
          }
        } else {
          setOneConversation3(null);
        }
        
      }
      
    };
    fetchConversation();
  }, [partitionKey1, rowKey1, partitionKey2, rowKey3, companyName, isRSI]);

  const handleQuestionSubmit = async (question: string) => {
    lastQuestionRef.current = question;
    const detectedLang = await detectLanguage(question);
    setDetectedLanguage(detectedLang || "en");

    const lastUserMessage: ChatTurn = {
      role: "user",
      content: question,
      augmented_content: "",
    };
    setHistory((prevHistory) => [...prevHistory, lastUserMessage]);

    let validAnswerCount = 0;

    if (!isRSI) {
      // Use conversation1 + conversation2
      if (rowKey1 && oneConversation) {
        const firstApiResult = await makeApiRequest(
          question,
          "Source: IMS General",
          oneConversation,
          api1,
          detectedLang || "en"
        );
        if (firstApiResult) {
          validAnswerCount++;
        }
      }
      // Use conversation2
      if (rowKey2 && oneConversation2) {
        const secondApiResult = await makeApiRequest(
          question,
          `Source: ${imsLocation}`,
          oneConversation2,
          api2,
          detectedLang || "en"
        );
        if (secondApiResult) {
          validAnswerCount++;
        }
      }
    } else {
      // Use conversation3
      if (rowKey3 && oneConversation3) {
        const thirdApiResult = await makeApiRequest(
          question,
          "Source: RSI Knowledge",
          oneConversation3,
          api3,
          detectedLang || "en"
        );
        if (thirdApiResult) {
          validAnswerCount++;
        }
      }
    }

    // If neither conversation had a valid answer
    const translatedInfo = await translateText(
      "We couldn't find an answer to your question based on on your selected knowledge sources. Please try asking another question.",
      detectedLang || "en"
    );

    if (validAnswerCount === 0) {
      const noAnswerMessage: ChatTurn = {
        role: "assistant",
        content:
          translatedInfo ||
          "We couldn't find an answer to your question based on the current knowledge sources. Please try asking another question.",
        data_points: [],
        cognitive_search_query: "",
        urls: [],
        categories: [],
        augmented_content: "",
      };
      setHistory((prevHistory) => [...prevHistory, noAnswerMessage]);
    }
  };

  const makeApiRequest = async (
    question: string,
    header: string,
    conversationData: OneConversationResponse,
    apiInstance: MlApi,
    detectedLang: string,
    hidden: string = ""
  ): Promise<boolean> => {
    setIsLoading(true);
    setActiveCitation("");
    setActiveAnalysisPanelTab(AnalysisPanelTabs.Closed);

    try {
      const request: ChatRequest = {
        message: question + hidden,
        history: history,
        approach: Approaches.ReadRetrieveRead,
        overrides: {
          promptTemplate:
            promptTemplate.length === 0 ? undefined : promptTemplate,
          top: retrieveCount,
          semanticRanker: useSemanticRanker,
          semanticCaptions: useSemanticCaptions,
          suggestFollowupQuestions: useSuggestFollowupQuestions,
        },
      };

      const result = await apiInstance.chat(
        request,
        conversationData.conversation
      );
      if (result.error) {
        setError(result.error);
        return false;
      } else if (result.answer && result.urls && result.urls.length > 0) {
        const translatedAnswer = await translateText(result.answer, detectedLang);
        if (translatedAnswer) {
          result.answer = translatedAnswer;
        }

        const lastAssistantMessage: ChatTurn = {
          role: "assistant",
          content: header + "\n" + result.answer,
          data_points: result.data_points,
          cognitive_search_query: result.cognitive_search_query,
          urls: result.urls,
          categories: result.categories,
          augmented_content: result.user_augmented_message,
        };
        setHistory((prevHistory) => [...prevHistory, lastAssistantMessage]);
        return true;
      }
      return false;
    } catch (e: any) {
      let err = "Unknown error.";
      if (e instanceof Error) err = e.message;
      setError(err);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const clearChat = () => {
    lastQuestionRef.current = "";
    setError("");
    setActiveCitation("");
    setActiveAnalysisPanelTab(AnalysisPanelTabs.Closed);
    setHistory([]);
  };

  useEffect(
    () => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }),
    [isLoading, history]
  );

  const onPromptTemplateChange = (
    _ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setPromptTemplate(newValue || "");
  };

  const onRetrieveCountChange = (value?: number) => {
    setRetrieveCount(value || 3);
  };

  const onUseSemanticRankerChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSemanticRanker(!!checked);
  };

  const onUseSemanticCaptionsChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSemanticCaptions(!!checked);
  };

  const onCategoryChange = (newValue?: Category[]) => {
    setSelectedCategories({ category: newValue || [] });
  };

  const onUseSuggestFollowupQuestionsChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSuggestFollowupQuestions(!!checked);
  };

  const onExampleClicked = (example: string) => {
    if (!isRSI && oneConversation) {
      makeApiRequest(example, "Example", oneConversation, api1, detectedLanguage);
    } else if (isRSI && oneConversation3) {
      makeApiRequest(example, "Example", oneConversation3, api3, detectedLanguage);
    }
  };

  const onShowCitation = (citation: string, index: number) => {
    if (
      activeCitation === citation &&
      activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab &&
      selectedAnswer === index
    ) {
      setActiveAnalysisPanelTab(AnalysisPanelTabs.Closed);
    } else {
      setActiveCitation(citation);
      setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
    }
    setSelectedAnswer(index);
  };

  const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
    if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
      setActiveAnalysisPanelTab(AnalysisPanelTabs.Closed);
    } else {
      setActiveAnalysisPanelTab(tab);
    }
    setSelectedAnswer(index);

    if (tab === AnalysisPanelTabs.ThoughtProcessTab) {
      onModalOpen();
    }
  };

  const showError: () => string = () => {
    if (!error) {
      return "No error";
    }
    if (typeof error === "string") {
      return error;
    }
    return error;
  };

  return (
    <Flex justify="center" className={styles.container} direction="column">
      <Flex direction="row" justify="center" className={styles.chatContainer}>
        <Flex direction="column">
          <Box className={styles.chat}>
            <Box className={styles.welcomeBubble} mb={4}>
              <ReactMarkdown
                components={ChakraUIRenderer()}
                children={welcomeMessage}
              />
            </Box>

            {history.map((message, index) => (
              <Box key={index}>
                {message.role === "user" ? (
                  <UserChatMessage message={message.content} />
                ) : (
                  <Box className={styles.gptAnswer}>
                    <Answer
                      api={!isRSI ? api1 : api3}
                      key={index}
                      question={lastQuestionRef.current}
                      answer={message}
                      history={history}
                      isSelected={
                        selectedAnswer === index &&
                        activeAnalysisPanelTab !== AnalysisPanelTabs.Closed
                      }
                      onCitationClicked={(c) => onShowCitation(c, index)}
                      onThoughtProcessClicked={() =>
                        onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)
                      }
                      onSupportingContentClicked={() =>
                        onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)
                      }
                      onFollowupQuestionClicked={(q) => {
                        if (!isRSI && oneConversation) {
                          makeApiRequest(
                            q,
                            "Follow up",
                            oneConversation,
                            api1,
                            detectedLanguage
                          );
                        } else if (isRSI && oneConversation3) {
                          makeApiRequest(
                            q,
                            "Follow up",
                            oneConversation3,
                            api3,
                            detectedLanguage
                          );
                        }
                      }}
                      showFollowupQuestions={
                        useSuggestFollowupQuestions &&
                        history.length - 1 === index
                      }
                      conversation={
                        !isRSI
                          ? oneConversation?.conversation
                          : oneConversation3?.conversation
                      }
                    />
                  </Box>
                )}
              </Box>
            ))}

            {isLoading && (
              <Box>
                <AnswerLoading />
              </Box>
            )}
            {error ? (
              <Box>
                <UserChatMessage message={lastQuestionRef.current} />
                <AnswerError
                  error={showError()}
                  onRetry={() => {
                    if (!isRSI && oneConversation) {
                      makeApiRequest(
                        lastQuestionRef.current,
                        "Ref",
                        oneConversation,
                        api1,
                        detectedLanguage || "en"
                      );
                    } else if (isRSI && oneConversation3) {
                      makeApiRequest(
                        lastQuestionRef.current,
                        "Ref",
                        oneConversation3,
                        api3,
                        detectedLanguage || "en"
                      );
                    }
                  }}
                />
              </Box>
            ) : null}
            <div ref={chatMessageStreamEnd} />
          </Box>
        </Flex>

        {history.length > 0 &&
          activeAnalysisPanelTab !== AnalysisPanelTabs.Closed && (
            <Flex>
              <AnalysisPanel
                question={
                  history[selectedAnswer - 1] || {
                    role: "assistant",
                    content: "",
                    augmented_content: "",
                  }
                }
                answer={history[selectedAnswer]}
                activeCitation={activeCitation}
                citationHeight="810px"
                activeTab={activeAnalysisPanelTab}
              />
            </Flex>
          )}

        <Drawer isOpen={isConfigPanelOpen} onClose={onConfigPanelClose}>
          <DrawerOverlay />
          <DrawerContent>
            <Box h="2em" />
            <DrawerHeader>Configure answer generation</DrawerHeader>
            <DrawerBody>
              <Input placeholder="Type here..." />

              <Box h="2em" />
              <Textarea
                defaultValue={promptTemplate}
                placeholder="Override prompt template"
                onChange={(e) =>
                  onPromptTemplateChange(undefined, e.target.value)
                }
              />
              <Box h="3em" />

              <Slider
                mb="2em"
                aria-label="slider-ex-1"
                min={1}
                max={50}
                defaultValue={retrieveCount}
                onChange={onRetrieveCountChange}
              >
                <SliderMark
                  value={retrieveCount}
                  textAlign="center"
                  bg="blue.500"
                  color="white"
                  mt="-10"
                  ml="-5"
                  w="12"
                >
                  {retrieveCount}
                </SliderMark>
                <SliderMark value={5} mt="5px" fontSize="sm">
                  Amount of documents to retrieve
                </SliderMark>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>

              <CheckboxGroup>
                <Checkbox
                  isChecked={useSemanticRanker}
                  onChange={onUseSemanticRankerChange}
                >
                  Use semantic ranker for retrieval
                </Checkbox>
                <Checkbox
                  mt="1em"
                  isChecked={useSemanticCaptions}
                  onChange={onUseSemanticCaptionsChange}
                  isDisabled={!useSemanticRanker}
                >
                  Use query-contextual summaries instead of whole documents
                </Checkbox>
                <Checkbox
                  mt="1em"
                  isChecked={useSuggestFollowupQuestions}
                  onChange={onUseSuggestFollowupQuestionsChange}
                >
                  Suggest follow-up questions
                </Checkbox>
              </CheckboxGroup>
            </DrawerBody>
            <DrawerCloseButton />
          </DrawerContent>
        </Drawer>

        <Modal isOpen={isModalOpen} onClose={onModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Complete Thought Process</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ReactMarkdown
                components={ChakraUIRenderer()}
                children={history[selectedAnswer]?.cognitive_search_query || ""}
                skipHtml
              />
              <ReactMarkdown
                components={ChakraUIRenderer()}
                children={history[selectedAnswer]?.augmented_content || ""}
                skipHtml
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onModalClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>

      <Flex className={styles.questionInputContainer} bg="#2f5291">
        <QuestionInput
          clearOnSend
          placeholder="Type in a question..."
          disabled={isLoading}
          onSend={(question) => handleQuestionSubmit(question)}
        />
        <ClearButton
          text="Clear chat"
          className={styles.clearChatButton}
          onClick={clearChat}
          disabled={!lastQuestionRef.current || isLoading}
        />
      </Flex>
    </Flex>
  );
};

export default Chat;
