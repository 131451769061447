// Layout.tsx

import { Outlet, Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  IPublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { useUserContext } from "../../context/UserContext"; // use context

const loginRequest = {
  scopes: ["User.Read", "User.Read.All"],
};

const callMsGraph = async (accessToken: string) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  const apiUrl =
    "https://graph.microsoft.com/v1.0/me?$select=displayName,mail,jobTitle,companyName,officeLocation,employeeId,manager&$expand=manager";

  try {
    const response = await fetch(apiUrl, options);
    const data = await response.json();
    console.log("Graph API response with extended fields:", data);
    return data;
  } catch (error) {
    console.error("Error calling MS Graph API:", error);
    return null;
  }
};

interface Props {
  instance: IPublicClientApplication;
  homeAccountId: string;
}

// Helper function to handle logout
const logout = (instance: IPublicClientApplication, homeAccountId: string) => {
  const logoutRequest = {
    account: instance.getAccountByHomeId(homeAccountId),
    mainWindowRedirectUri: "/",
    postLogoutRedirectUri: "/",
  };
  instance.logoutPopup(logoutRequest);
};

const Layout = ({ instance, homeAccountId }: Props) => {
  const { accounts } = useMsal();
  const { companyName, setCompanyName, email, setEmail } = useUserContext();
  const [userData, setUserData] = useState<any>(null);

  useEffect(() => {
    // If we have no MSAL accounts, skip fetching Graph
    if (!accounts || accounts.length === 0) {
      console.debug("No accounts yet, skipping Graph fetch");
      return;
    }

    // Make sure there's an active account
    let activeAcc = instance.getActiveAccount() || accounts[0];
    if (!activeAcc) {
      // If MSAL didn't set it, do so manually
      instance.setActiveAccount(accounts[0]);
      activeAcc = accounts[0];
    }

    const fetchGraphData = async () => {
      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: activeAcc,
        });

        // Call MS Graph
        const data = await callMsGraph(response.accessToken);
        setUserData(data);

        // Update context from Graph
        if (data) {
          setEmail(data.mail || activeAcc.username);
          if (data.companyName) {
            setCompanyName(data.companyName);
          }
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to popup
          try {
            const response = await instance.acquireTokenPopup({
              ...loginRequest,
              account: activeAcc,
            });
            const data = await callMsGraph(response.accessToken);
            setUserData(data);

            if (data) {
              setEmail(data.mail || activeAcc.username);
              if (data.companyName) {
                setCompanyName(data.companyName);
              }
            }
          } catch (popupError) {
            console.error("Error during popup token acquisition:", popupError);
          }
        } else {
          console.error("Error acquiring token silently:", error);
        }
      }
    };

    fetchGraphData();
  }, [accounts, instance, setCompanyName, setEmail]);

  return (
    <Flex direction="column">
      <Flex
        as="header"
        bg="#2f5291"
        p="4"
        align="center"
        justify="space-between"
        width="100%"
      >
        <Flex
          direction="row"
          align="center"
          justify="space-between"
          maxW="1200px"
          width="100%"
          mx="auto"
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            <Text fontSize="lg" color="white">
              Hello,{" "}
              {userData ? (
                <>
                  {userData.displayName}
                  {userData.companyName ? ` from ${userData.companyName}` : ""}
                </>
              ) : (
                // Fallback if userData not loaded yet
                accounts[0]?.username
              )}
            </Text>
          </Link>
          <Link
            to="/"
            onClick={() => homeAccountId && logout(instance, homeAccountId)}
            style={{ textDecoration: "none" }}
          >
            <Text fontSize="lg" color="white">
              Logout
            </Text>
          </Link>
        </Flex>
      </Flex>

      {/* Outlet for nested routes to render */}
      <Outlet />
    </Flex>
  );
};

export default Layout;
