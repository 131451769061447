// src/appInsights.ts
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "ff8e46a9-5691-4d96-8015-f6f445ed93e5",
    enableCorsCorrelation: true,
    disableFetchTracking: false,
  }
});

appInsights.loadAppInsights();
appInsights.trackPageView(); // Initial page view

export default appInsights;
