import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Browser = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Automatically redirect to the desired chat page
    navigate("/ai/chat/0/e813c88dd93f48f08d30e715d3f1d888");
  }, [navigate]);

  return null; // Render nothing since you want to skip this page entirely
};

export default Browser;
