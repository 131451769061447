// UserContext.tsx

import React, { createContext, useContext, useState, ReactNode } from "react";

// 1. Extend the interface to include email and its setter
interface UserContextType {
  companyName: string | null;
  setCompanyName: (companyName: string) => void;
  email: string | null;
  setEmail: (email: string) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  // 2. Add state for both companyName and email
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  return (
    <UserContext.Provider value={{ companyName, setCompanyName, email, setEmail }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
